import React from 'react';
import { graphql } from "gatsby"

import Layout from "../App/components/layout"
import SEO from "../App/components/seo"


export const data = graphql`
query{
  craft{
    entry(slug: "terms"){
        ...on Craft_Terms{
          body{
            content
          }
        }
      }
  }
}
`;

const termsPage = ( {data} ) => (
    <Layout>
      <SEO title="" />
      <div className="wrapper">
        <h1>Terms</h1>
            <td dangerouslySetInnerHTML={{__html: data.craft.entry.body.content}} />
        </div>
    </Layout>
)

export default termsPage;
